import { useEffect } from 'react';
import { render } from 'react-dom';

import './style.scss';
import Style from './Style';
import { performCounter } from './utils/functions';

// Counters
document.addEventListener('DOMContentLoaded', () => {
	const allCounters = document.querySelectorAll('.wp-block-ctrb-counters');
	allCounters.forEach(counters => {
		const attributes = JSON.parse(counters.dataset.attributes);

		render(<Counters attributes={attributes} countersEl={counters} />, counters);

		counters?.removeAttribute('data-attributes');
	});
});

const Counters = ({ attributes, countersEl }) => {
	const { counters = [], columns, isIcon, iconPosition, isLabel } = attributes;

	useEffect(() => {
		const counterEl = document.querySelectorAll(`#${countersEl.id} .ctrbCounters .counterNumber .number`);

		const intersectionObserver = new IntersectionObserver((entries) => {
			if (entries[0].intersectionRatio <= 0) return;

			performCounter(counterEl, attributes.duration);
			intersectionObserver.unobserve(entries[0].target); // Remove if use multiple
		});
		intersectionObserver.observe(document.querySelector(`#${countersEl.id} .ctrbCounters .counterNumber .number`));
	}, []);

	return <>
		<Style attributes={attributes} clientId={attributes.cId} />

		<div className={`ctrbCounters columns-${columns.desktop} columns-tablet-${columns.tablet} columns-mobile-${columns.mobile}`}>
			{counters?.map((item, index) => {
				const { icon, number, label } = item;

				const iconEl = icon?.class && <i className={icon.class}></i>;
				const labelEl = isLabel && label.text && <p className='counterLabel' dangerouslySetInnerHTML={{ __html: label.text }} />

				return <div key={index} id={`ctrbCounter-${index}`}>
					<div className='ctrbCounter'>
						{isIcon && 'top' === iconPosition && <div className='counterIcon'>{iconEl}</div>}

						<div className='counterNumber'>
							<span className='prefix'>{number.prefix}</span>
							<span className='number' data-count-start={number.start} data-count-end={number.end}>{number.start}</span>
							<span className='suffix'>{number.suffix}</span>
						</div>

						{'label' === iconPosition ? <div className='counterIconLabel'>{iconEl}{labelEl}</div> : labelEl}
					</div>
				</div>;
			})}
		</div>
	</>;
}