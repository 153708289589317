import { getBackgroundCSS, getBorderCSS, getIconCSS, getShadowCSS, getSpaceCSS, getTypoCSS } from '../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { counters = [], columnGap, rowGap, background, padding, textAlign, counterWidth, counterHeight, iconSize, numberTypo, labelTypo, counterPadding, counterBorder, counterShadow } = attributes;

	const mainSl = `#ctrbCounters-${clientId}`;
	const countersSl = `${mainSl} .ctrbCounters`;
	const gCounterSl = `${countersSl} .ctrbCounter`;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			${getTypoCSS('', numberTypo)?.googleFontLink}
			${getTypoCSS('', labelTypo)?.googleFontLink}
			${getTypoCSS(`${gCounterSl} .counterNumber`, numberTypo)?.styles}
			${getTypoCSS(`${gCounterSl} .counterLabel`, labelTypo)?.styles}
			
			${countersSl}{
				grid-gap: ${rowGap} ${columnGap};
				${getBackgroundCSS(background)}
				padding: ${getSpaceCSS(padding)};
			}
			${gCounterSl}{
				text-align: ${textAlign};
				width: ${'0px' === counterWidth || '0%' === counterWidth || '0em' === counterWidth ? '250px' : counterWidth};
				height: ${'0px' === counterHeight || '0%' === counterHeight || '0em' === counterHeight ? '250px' : counterHeight};
				padding: ${getSpaceCSS(counterPadding)};
				${getBorderCSS(counterBorder)}
				box-shadow: ${getShadowCSS(counterShadow)};
			}
			${gCounterSl} .counterIcon,
			${gCounterSl} .counterIconLabel{
				font-size: ${iconSize || 50}px;
			}
			${counters?.map((item, index) => {
				const counterSl = `${mainSl} #ctrbCounter-${index}`;

				return `
					${counterSl} .ctrbCounter{
						${getBackgroundCSS(item.background)}
					}
					${counterSl} .counterIcon i,
					${counterSl} .counterIconLabel i{
						${getIconCSS(item.icon)}
						font-size: inherit;
					}
					${counterSl} .counterNumber{
						color: ${item.number.color};
					}
					${counterSl} .counterLabel{
						color: ${item.label.color};
					}
				`
			}).join(' ')}
			`.replace(/\s+/g, ' ')
		}} />
	</>
}
export default Style;